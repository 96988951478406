function counterLoad() {
	$('.block-counter__value-number').each(function () {
		let a = $(this).text();
		a = a.indexOf(',') > -1 ? a.replace(',', '.') : a;

		const x = a.split('.')[1] ? a.split('.')[1] : '';
		const b = parseInt(a);

		$(this)
			.prop('Counter', 0)
			.animate(
				{
					Counter: b,
				},
				{
					duration: 3000,
					easing: 'swing',
					step(now) {
						let e =
							parseInt(Math.ceil(now)) +
							parseFloat(parseInt(a) - b);

						e = $(this).text().indexOf(',') > -1 ? e + ',' + x : e;
						e = $(this).text().indexOf('.') > -1 ? e + '.' + x : e;

						$(this).text(e);
					},
				}
			);
	});
}

$(document).ready(function () {
	counterLoad();
});
