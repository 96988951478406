const fadeInStart = 'top 70%';
const fadeInToggle = 'restart none none reverse';
const fadeInDuration = 0.75;

gsap.utils.toArray('.fade-in-up').forEach((fade) => {
	gsap.fromTo(
		fade,
		{
			autoAlpha: 0,
			y: 50,
		},
		{
			scrollTrigger: {
				trigger: fade,
				start: fadeInStart,
				toggleActions: fadeInToggle,
			},
			autoAlpha: 1,
			y: 0,
			duration: fadeInDuration,
		}
	);
});

gsap.utils.toArray('.fade-in-down').forEach((fade) => {
	gsap.fromTo(
		fade,
		{
			autoAlpha: 0,
			y: -50,
		},
		{
			scrollTrigger: {
				trigger: fade,
				start: fadeInStart,
				toggleActions: fadeInToggle,
			},
			autoAlpha: 1,
			y: 0,
			duration: fadeInDuration,
		}
	);
});

gsap.utils.toArray('.fade-in-left').forEach((fade) => {
	gsap.fromTo(
		fade,
		{
			autoAlpha: 0,
			x: 50,
		},
		{
			scrollTrigger: {
				trigger: fade,
				start: fadeInStart,
				toggleActions: fadeInToggle,
			},
			autoAlpha: 1,
			x: 0,
			duration: fadeInDuration,
		}
	);
});

gsap.utils.toArray('.fade-in-right').forEach((fade) => {
	gsap.fromTo(
		fade,
		{
			autoAlpha: 0,
			x: -50,
		},
		{
			scrollTrigger: {
				trigger: fade,
				start: fadeInStart,
				toggleActions: fadeInToggle,
			},
			autoAlpha: 1,
			x: 0,
			duration: fadeInDuration,
		}
	);
});
