/* eslint-disable prettier/prettier */
// Bootstrap Imports
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Tab from "bootstrap/js/dist/tab"; // eslint-disable-line no-unused-vars

import "./animations";

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	$("html").removeClass("no-js").addClass("js");
}

// Optimize the scroll events for better browser performance.
//
// Broadcasts optimizedScroll event on the window.
// Use the same way you would add a window scroll listener.
// window.on('optimizedScroll', ...);
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	window.addEventListener("scroll", () => {
		if (scrollWaiting) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout(endScrollHandle);

		window.dispatchEvent(new Event("optimizedScroll"));

		setTimeout(function () {
			scrollWaiting = false;
		}, 100);

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout(function () {
			window.dispatchEvent(new Event("optimizedScroll"));
		}, 200);
	});
}

function mainNavigationScroll() {
	const scrollY = window.scrollY;
	const masthead = document.getElementById("masthead");
	const primaryMenu = document.getElementById("primary-menu-collapse");

	if (scrollY > 40) {
		masthead.classList.remove("top");
		if (document.body.classList.contains("navigation-overlay")) {
			primaryMenu.classList.remove("navbar-dark");
			primaryMenu.classList.add("navbar-light");
		}
	} else {
		masthead.classList.add("top");
		if (document.body.classList.contains("navigation-overlay")) {
			primaryMenu.classList.remove("navbar-light");
			primaryMenu.classList.add("navbar-dark");
		}
	}
}

function mainNavigation() {
	// Load the scroll state
	window.addEventListener("optimizedScroll", function () {
		mainNavigationScroll();
	});

	const navCollapseEl = document.getElementById("mobile-menu-collapse");
	const masthead = document.getElementById("masthead");

	// Should match the media query for the responsive navbar
	const isMobile = function () {
		const mediaQuery = window.matchMedia("(min-width: 992px)");
		return !mediaQuery.matches;
	};

	// Use Popper on navbar dropdowns
	Dropdown.prototype._detectNavbar = function () {
		return false;
	};

	// Solution to allow nested dropdowns with BSv5
	// https://jsfiddle.net/dallaslu/mvk4uhzL/
	const SHOW_CLASS_NAME = "has-child-dropdown-show";
	Dropdown.prototype.toggle = (function (_orginal) {
		return function () {
			document
				.querySelectorAll("." + SHOW_CLASS_NAME)
				.forEach(function (e) {
					e.classList.remove(SHOW_CLASS_NAME);
				});
			let dd = this._element
				.closest(".dropdown")
				.parentNode.closest(".dropdown");
			for (
				;
				dd && dd !== document;
				dd = dd.parentNode.closest(".dropdown")
			) {
				dd.classList.add(SHOW_CLASS_NAME);
			}
			return _orginal.call(this);
		};
	})(Dropdown.prototype.toggle);

	document.querySelectorAll(".dropdown").forEach(function (dd) {
		dd.addEventListener("hide.bs.dropdown", function (e) {
			if (this.classList.contains(SHOW_CLASS_NAME)) {
				this.classList.remove(SHOW_CLASS_NAME);
				e.preventDefault();
			}
			e.stopPropagation(); // do not need pop in multi level mode
		});
	});

	// Opt-in enable hover toggle on dropdowns
	// Add .dropdown-hover to the .dropdown, or add .dropdown-hover-all to a parent element.
	//
	// Note: it is generally a better idea to enable dropdowns on click.
	document
		.querySelectorAll(".dropdown-hover, .dropdown-hover-all .dropdown")
		.forEach(function (dd) {
			dd.addEventListener("mouseenter", function (e) {
				if (isMobile()) return;
				const toggle = e.target.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if (!toggle.classList.contains("show")) {
					Dropdown.getOrCreateInstance(toggle).toggle();
					dd.classList.add(SHOW_CLASS_NAME);
					Dropdown.clearMenus();
				}
			});
			dd.addEventListener("mouseleave", function (e) {
				if (isMobile()) return;
				const toggle = e.target.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if (toggle.classList.contains("show")) {
					Dropdown.getOrCreateInstance(toggle).toggle();
				}
			});
			// Redirect to link if it exists/is valid.
			dd.addEventListener("click", function () {
				if (isMobile() || this.classList.contains("dropdown-split"))
					return;
				const toggle = this.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if (!toggle.getAttribute("href").lastIndexOf("#", 0) !== 0) {
					window.location.href = toggle.getAttribute("href");
				}
			});
		});

	// Prevent scroll while open
	navCollapseEl.addEventListener("show.bs.collapse", function () {
		masthead.classList.add("open");
	});
	navCollapseEl.addEventListener("hidden.bs.collapse", function () {
		masthead.classList.remove("open");
	});

	// Close main navigation on resize
	window.addEventListener("resize", function () {
		if (
			typeof navCollapseEl !== "undefined" &&
			navCollapseEl.classList.contains("show")
		) {
			const navCollapse = Collapse.getInstance(navCollapseEl);
			navCollapse.hide();
			masthead.classList.remove("open");
		}
	});
}

/**
 * Use Bootstrap framework styles with Ninja Forms validation.
 */
function ninjaValidation() {
	if (typeof Marionette !== "undefined") {
		const ninjaValidationController = Marionette.Object.extend({
			initialize() {
				this.listenTo(
					Backbone.Radio.channel("fields"),
					"add:error",
					this.addError
				);

				this.listenTo(
					Backbone.Radio.channel("fields"),
					"remove:error",
					this.removeError
				);
			},

			addError(response) {
				const $el = $("#nf-field-" + response.id);
				$el.addClass("is-invalid");
			},

			removeError(response) {
				const $el = $("#nf-field-" + response.id);
				$el.removeClass("is-invalid");
			},
		});

		// Instantiate our validation controller, defined above.
		new ninjaValidationController();
	}
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

/**
 * On Loads
 */

document.addEventListener("DOMContentLoaded", () => {
	fouc();
	optimizeScroll();
	mainNavigation();
	mainNavigationScroll();
	ninjaValidation();
});
