import Filterizr from 'filterizr';

const options = {
	gridItemsSelector: '.file',
};

$(document).ready(function () {
	if ($('.forms-container').length < 1) return;

	new Filterizr('.forms-container', options);

	let formElements = $('.file').sort(function (a, b) {
		const contentA = $(a).attr('name');
		const contentB = $(b).attr('name');
		if (contentA < contentB) {
			return -1;
		}
		if (contentA > contentB) {
			return 1;
		}
		return 0;
	});
	formElements = [...formElements];
	for (let i = 0; i < formElements.length; i++) {
		formElements[i].style.order = i;
	}
});
